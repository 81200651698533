import { Fetcher } from 'jso'
import OAuthClient from '../global/auth'

export const getCompanyEntities = async (companyID) => {
  const f = new Fetcher(OAuthClient)
  const url = `${
    import.meta.env.VITE_THETREEP_API_URL
  }/entity/?${new URLSearchParams({
    full: 'true',
    limit: '2000',
    offset: '0',
    order_by: 'ASC',
    company_id: companyID,
    sort_by: 'id',
  })}`
  const response = await f.fetch(url, {})
  const responseObject = await response.json()
  return responseObject.result || []
}

export const setUserEntities = async (user, entities) => {
  const f = new Fetcher(OAuthClient)

  const currentUserEntities = []
  if (user.entities.length > 0) {
    currentUserEntities.push(...user.entities.map((ent) => ent.uid))
  }

  const newUserEntities = [...new Set([
    ...currentUserEntities,
    ...entities.map((ent) => ent.entity),
  ])]

  return f
    .fetch(`${import.meta.env.VITE_THETREEP_API_URL}/user/entities`, {
      method: 'POST',
      body: JSON.stringify({
        user_ids: [user.uid],
        entity_ids: newUserEntities,
      }),
    })
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        return response.text().then((res) => {
          throw new Error(res)
        })
      } else {
        return response.json()
      }
    })
    .catch((error) => {
      throw new Error(error)
    })
}
