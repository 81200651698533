export const en = {
  uploadStep: {
    title: 'Upload file',
    manifestTitle: 'Data that we expect:',
    manifestDescription:
      '(You will have a chance to rename or remove columns in next steps)',
    maxRecordsExceeded: (maxRecords) =>
      `Too many records. Up to ${maxRecords} allowed`,
    dropzone: {
      title: 'Upload .xlsx, .xls or .csv file',
      errorToastDescription: 'upload rejected',
      activeDropzoneTitle: 'Drop file here...',
      buttonTitle: 'Select file',
      loadingTitle: 'Processing...',
    },
    selectSheet: {
      title: 'Select the sheet to use',
      nextButtonTitle: 'Next',
    },
  },
  selectHeaderStep: {
    title: 'Select header row',
    nextButtonTitle: 'Next',
  },
  matchColumnsStep: {
    title: 'Match Columns',
    nextButtonTitle: 'Next',
    userTableTitle: 'Your table',
    templateTitle: 'Will become',
    selectPlaceholder: 'Select column...',
    ignoredColumnText: 'Column ignored',
    subSelectPlaceholder: 'Select...',
    matchDropdownTitle: 'Match',
    unmatched: 'Unmatched',
    duplicateColumnWarningTitle: 'Another column unselected',
    duplicateColumnWarningDescription: 'Columns cannot duplicate',
  },
  validationStep: {
    title: 'Validate data',
    nextButtonTitle: 'Confirm',
    noRowsMessage: 'No data found',
    noRowsMessageWhenFiltered: 'No data containing errors',
    discardButtonTitle: 'Discard selected rows',
    filterSwitchTitle: 'Show only rows with errors',
  },
  alerts: {
    confirmClose: {
      headerTitle: 'Exit import flow',
      bodyText: 'Are you sure? Your current information will not be saved.',
      cancelButtonTitle: 'Cancel',
      exitButtonTitle: 'Exit flow',
    },
    submitIncomplete: {
      headerTitle: 'Errors detected',
      bodyText:
        'There are still some rows that contain errors. Rows with errors will be ignored when submitting.',
      bodyTextSubmitForbidden: 'There are still some rows containing errors.',
      cancelButtonTitle: 'Cancel',
      finishButtonTitle: 'Submit',
    },
    unmatchedRequiredFields: {
      headerTitle: 'Not all columns matched',
      bodyText:
        'There are required columns that are not matched or ignored. Do you want to continue?',
      listTitle: 'Columns not matched:',
      cancelButtonTitle: 'Cancel',
      continueButtonTitle: 'Continue',
    },
    toast: {
      error: 'Error',
    },
  },
}

export const fr = {
  uploadStep: {
    title: 'Téléverser un fichier',
    manifestTitle: 'Données attendues:',
    manifestDescription:
      '(Vous pourrez renommer ou supprimer des colonnes lors de la prochaine étape)',
    maxRecordsExceeded: (maxRecords) =>
      `Trop de données. Un maximum de ${maxRecords} lignes est autorisé`,
    dropzone: {
      title: 'Téléverser un fichier .xlsx, .xls ou .csv',
      errorToastDescription: 'téléversement rejeté',
      activeDropzoneTitle: 'Déposez votre fichier ici...',
      buttonTitle: 'Sélectionner un fichier',
      loadingTitle: 'Chargement...',
    },
    selectSheet: {
      title: 'Sélectionnez la feuille à utiliser',
      nextButtonTitle: 'Suivant',
    },
  },
  selectHeaderStep: {
    title: 'Sélectionner la ligne header',
    nextButtonTitle: 'Suivant',
  },
  matchColumnsStep: {
    title: 'Assignation des colonnes',
    nextButtonTitle: 'Suivant',
    userTableTitle: 'Votre tableau',
    templateTitle: 'Deviendra',
    selectPlaceholder: 'Sélectionner colonne...',
    ignoredColumnText: 'Colonne ignorée',
    subSelectPlaceholder: 'Sélectionner...',
    matchDropdownTitle: 'Assignation',
    unmatched: 'Non assigné',
    duplicateColumnWarningTitle: "Une autre colonne n'est pas sélectionnée",
    duplicateColumnWarningDescription: 'Les colonnes ne peuvent être en double',
  },
  validationStep: {
    title: 'Validation des données',
    nextButtonTitle: 'Confirmer',
    noRowsMessage: 'Pas de données trouvées',
    noRowsMessageWhenFiltered: 'Aucune donnée',
    discardButtonTitle: 'Ignorer les lignes sélectionnées',
    filterSwitchTitle: 'Voir uniquement les lignes erronées',
  },
  alerts: {
    confirmClose: {
      headerTitle: "Quitter le processus d'import",
      bodyText:
        'Êtes vous sûr(e)? Les informations affichées ne seront pas sauvegardées.',
      cancelButtonTitle: 'Annuler',
      exitButtonTitle: 'Quitter',
    },
    submitIncomplete: {
      headerTitle: 'Erreurs détectées',
      bodyText:
        "Il y a toujours des lignes erronées. Ces dernières seront ignorées lors de l'import.",
      bodyTextSubmitForbidden: 'Il y a toujours des données erronées.',
      cancelButtonTitle: 'Annuler',
      finishButtonTitle: 'Soumettre',
    },
    unmatchedRequiredFields: {
      headerTitle: 'Toutes les colonnes ne sont pas assignées',
      bodyText:
        'Certaines colonnes requises ne sont pas assignées ou ignorées. Voulez-vous continuer?',
      listTitle: 'Colonnes non assignées:',
      cancelButtonTitle: 'Annuler',
      continueButtonTitle: 'Continuer',
    },
    toast: {
      error: 'Erreur',
    },
  },
}
