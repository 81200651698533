import RandExp from 'randexp'
import countries from './countries'

const userFields = [
  {
    label: 'Civilité',
    key: 'gender',
    alternateMatches: ['civilité', 'civilite', 'genre', 'sexe', 'PREFIX'],
    fieldType: {
      type: 'select',
      options: [
        { label: 'M', value: 'MR' },
        { label: 'MME', value: 'MS' },
      ],
    },
    example: 'M',
    validations: [
      {
        rule: 'required',
        errorMessage: 'la civilité est requise',
        level: 'error',
      },
    ],
  },
  {
    label: 'Nom de famille',
    key: 'lastname',
    alternateMatches: ['nom', 'nom de famille', 'LAST_NAME'],
    fieldType: {
      type: 'input',
    },
    example: 'Durand',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Le nom de famille est requis',
        level: 'error',
      },
    ],
  },
  {
    label: 'Prénom',
    key: 'firstname',
    alternateMatches: ['prénom', 'prenom', 'FIRST_NAME'],
    fieldType: {
      type: 'input',
    },
    example: 'Jean',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Le prénom est requis',
        level: 'error',
      },
    ],
  },
  {
    label: 'Email',
    key: 'email',
    alternateMatches: ['e-mail', 'mail', 'mél', 'EMAIL'],
    fieldType: {
      type: 'input',
    },
    example: 'jean.durand@thetreep.com',
    validations: [
      {
        rule: 'regex',
        value: '^\\b[\\w\\.-]+@[\\w\\.-]+\\.\\w{2,4}\\b$',
        errorMessage: "Le format de l'email est invalide",
        level: 'error',
      },
      {
        rule: 'required',
        errorMessage: "L'email est requis",
        level: 'error',
      },
    ],
  },
  {
    label: "Nom d'utilisateur",
    key: 'username',
    alternateMatches: ["Nom d'utilisateur", 'utilisateur', 'login', 'LOGIN'],
    fieldType: {
      type: 'input',
    },
    example: 'jean.durand@thetreep.com',
    validations: [
      {
        rule: 'regex',
        value: '^[A-Za-z][A-Za-z0-9_.@-]{3,255}$',
        errorMessage:
          "Le format de nom d'utilisateur est non valide. Il doit comprendre au minimum 4 caractères, et ne peut contenir que les caractères spéciaux suivants: _, ., @, -",
        level: 'error',
      },
      {
        rule: 'unique',
        errorMessage: "Le nom d'utilisateur doit être unique",
        level: 'error',
      },
      {
        rule: 'required',
        errorMessage: "Le nom d'utilisateur est requis",
        level: 'error',
      },
    ],
  },
  {
    label: 'Date de naissance',
    key: 'birthdate',
    alternateMatches: [
      'date de naissance',
      'DATE_OF_BIRTH',
      'DATE DE NAISSANCE',
    ],
    fieldType: {
      type: 'input',
    },
    example: '25/02/1987',
    validations: [
      {
        rule: 'regex',
        value:
          '(^$)|(^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[012])[-/.](19|20)\\d\\d$)',
        errorMessage: 'La date de naissance doit être au format DD/MM/YYYY',
        level: 'error',
      },
    ],
  },
  {
    label: 'Numéro de téléphone',
    key: 'phone',
    alternateMatches: [
      'telephone',
      'téléphone',
      'numéro',
      'numéro de téléphone',
      'MOBILE_PHONE',
    ],
    fieldType: {
      type: 'input',
    },
    example: '+33123456789',
    validations: [
      {
        rule: 'regex',
        value: '(^$)|(^\\+?(?:[0-9] ?){6,14}[0-9]$)',
        errorMessage:
          'Le numéro de téléphone devrait être au format international E.164. Exemple: +33611223344',
        level: 'warning',
      },
    ],
  },
  {
    label: "Niveau d'approbation",
    key: 'approval_level',
    alternateMatches: ["Niveau d'approbation", 'niveau'],
    fieldType: {
      type: 'input',
    },
    example: '2',
    validations: [
      {
        rule: 'regex',
        value: '(^$)|(^\\d+$)',
        errorMessage: "Le niveau d'approbation doit être un entier",
        level: 'error',
      },
    ],
  },
  {
    label: 'Rôle',
    key: 'role',
    alternateMatches: ['rôle', 'permission', 'type'],
    fieldType: {
      type: 'select',
      options: [
        { value: 'pro_traveler', label: 'Voy. sous appro' },
        {
          value: 'auth_pro_traveler',
          label: 'Voyageur',
        },
        {
          value: 'pro_traveler_service_searcher',
          label: 'Voy. sous appro - Service',
        },
        {
          value: 'auth_pro_traveler_service_searcher',
          label: 'Voy. - Service',
        },
        { value: 'approver', label: 'Approbateur' },
        {
          value: 'supervisor',
          label:
            'Superviseur (Accès backoffice en plus, peut approuver via le BO)',
        },
        { value: 'auth_travel_manager', label: 'Ch. de voyages' },
        { value: 'travel_manager', label: 'Ch. de voyages sous appro' },
        {
          value: 'travel_agent',
          label: 'Agt. de voyages (Toutes entreprises!)',
        },
      ],
    },
    example: 'Voy. sous appro',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Le rôle est requis',
        level: 'error',
      },
    ],
  },
  {
    label: 'Service',
    key: 'service',
    alternateMatches: ['departement', 'département'],
    fieldType: {
      type: 'input',
    },
    example: 'Comptabilité',
  },
  {
    label: 'Catégorie de voyageur',
    key: 'category',
    alternateMatches: [
      'catégorie',
      'type',
      'Catégorie',
      'Categorie',
      'categorie',
    ],
    fieldType: {
      type: 'input',
    },
    example: 'Cadre',
  },
  {
    label: 'Poste',
    key: 'job',
    alternateMatches: ['Poste', 'job'],
    fieldType: {
      type: 'input',
    },
    example: 'Chef de projet',
  },
  {
    label: 'Ville de naissance',
    key: 'city_of_birth',
    alternateMatches: ['Ville de naissance'],
    fieldType: {
      type: 'input',
    },
    example: 'Lyon',
  },
  {
    label: 'Pays de naissance',
    key: 'country_of_birth',
    alternateMatches: ['Pays de naissance'],
    fieldType: {
      type: 'select',
      options: countries,
    },
    example: 'France',
  },
]

const withRequiredAnalytics = (company) =>
  company.user_analytics.map((analytic) => {
    const analyticField = {
      label: analytic.label,
      key: analytic.uid,
      alternateMatches: [analytic.label, analytic.name],
      fieldType: {
        type: 'input',
      },
      example: '123456',
      validations: [],
    }

    if (analytic.key) {
      analyticField.alternateMatches.push(analytic.key)
    }

    if (analytic.values) {
      analyticField.fieldType.type = 'select'
      analyticField.fieldType.options = []
      analytic.values.forEach((value) => {
        analyticField.fieldType.options.push({ label: value, value })
      })
    }

    if (analytic.required) {
      analyticField.validations.push({
        rule: 'required',
        errorMessage: 'cet analytique est requis',
        level: 'error',
      })
    }

    if (analytic.regex) {
      try {
        new RegExp(analytic.regex)
      } catch (e) {
        console.error('regex is invalid')
        return null
      }
      analyticField.example = new RandExp(analytic.regex).gen()
      analyticField.validations.push({
        rule: 'regex',
        value: analytic.regex,
        errorMessage: `Exemples de formats valides: ${new RandExp(
          analytic.regex,
        ).gen()}, ${new RandExp(analytic.regex).gen()}`,
        level: 'error',
      })
    }
    return analyticField
  })

const withDirectApprover = () => [
  {
    label: 'Approbateur',
    key: 'approvers',
    alternateMatches: [
      'approbateur',
      'appro',
      'n+1',
      'supérieur',
      'manager',
      'approver',
    ],
    fieldType: {
      type: 'input',
    },
    example: 'jean.durand@thetreep.com',
    validations: [
      {
        rule: 'regex',
        value: '(^$)|(^[A-Za-z][A-Za-z0-9_.@-]{3,255}$)',
        errorMessage:
          "Le format de nom d'utilisateur est non valide. Il doit comprendre au minimum 4 caractères, et ne peut contenir que les caractères spéciaux suivants: _, ., @, -",
        level: 'error',
      },
    ],
  },
]

const decoratedUserFields = (company) => {
  const uf = [...userFields]
  if (company.user_analytics) {
    uf.push(...withRequiredAnalytics(company))
  }
  if (company.config?.approvalType === 'hierarchical') {
    uf.push(...withDirectApprover(company))
  }
  return uf
}

export { userFields, decoratedUserFields, withRequiredAnalytics }
