import { getUserByUsername, setApprovees } from '../services/userService'

const approversImportHandler = async (userGroup) => {
  const approver = await getUserByUsername(userGroup.approver)
  if (!approver.uid) {
    throw new Error(`User ${userGroup.approver} not found`)
  }
  const approveesUIDs = await Promise.all(
    userGroup.approvees.map(async (approvee) => {
      const u = await getUserByUsername(approvee.username)
      if (!u.uid) {
        throw new Error(`User ${approvee.username} not found`)
      }
      return u.uid
    }),
  )
  return setApprovees(approver?.uid, approveesUIDs)
}

const approversImportBatcher = (data) => {
  const userGroupsAsObject = data.validData.reduce((acc, d) => {
    if (acc[d.approver] || d.approver === '') {
      return acc
    }
    acc[d.approver] = data.validData.filter((g) => g.approver === d.approver)
    return acc
  }, {})

  const userGroupsAsArray = []
  Object.entries(userGroupsAsObject).forEach((userGroup) => {
    const [key, value] = userGroup
    userGroupsAsArray.push({ approver: key, approvees: value })
  })

  return userGroupsAsArray
}

export { approversImportHandler, approversImportBatcher }
