import dayjs from 'dayjs'
import { getUserByUsername, postUserDocument } from '../services/userService'
import { frenchDateToAPI } from './utils/utils'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const documentsImportHandler = async (document) => {
  const user = await getUserByUsername(document.username)

  // eslint-disable-next-line no-unused-vars
  const { username, ...documentObject } = document

  const sanitizedDocument = {
    travel_document: {
      ...documentObject,
      user_id: user.uid,
      effective_date: frenchDateToAPI(document?.effective_date),
      expiration_date: document?.expiration_date
        ? frenchDateToAPI(document?.expiration_date)
        : document.type !== 'driver_license'
        ? dayjs(document?.effective_date, 'DD/MM/YYYY')
            .add(10, 'year')
            .format('YYYYMMDDT120000')
        : '',
    },
  }

  return postUserDocument(sanitizedDocument)
}

export default documentsImportHandler
