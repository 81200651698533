import { getUserByUsername, updateUser } from '../services/userService'

const analyticsImportHandler = async (data, company) => {
  const user = await getUserByUsername(data.username)

  const updatedUser = {
    ...user,
    analytics: user.analytics ?? [],
  }

  company.user_analytics.forEach((analytic) => {
    if (!data[analytic.uid]) {
      return
    }

    const userAnalytic = {
      analytic,
      value: data[analytic.uid],
    }

    const existingUserAnalyticIndex = updatedUser.analytics.findIndex(
      (an) => an.analytic.uid === analytic.uid,
    )

    if (existingUserAnalyticIndex > -1) {
      updatedUser.analytics[existingUserAnalyticIndex] = userAnalytic
    } else {
      updatedUser.analytics.push(userAnalytic)
    }
  })

  return updateUser(updatedUser)
}

export default analyticsImportHandler
