import {
  ChakraProvider,
  Box,
  Container,
  Flex,
  Center,
  Heading,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import OAuthClient from '../../global/auth'
import CompanySelect from '../../components/CompanySelect'
import Header from '../../components/Header'
import Import from '../../components/Import'
import { theme } from '../../global/theme'
import { useToken } from '../../hooks/useToken'
import { getCurrentUser } from '../../services/userService'
import { getCompanies } from '../../services/companyService'
import { useTranslation } from 'react-i18next'

const Welcome = () => {
  const [step, setStep] = useState(1)
  const [companies, setCompanies] = useState([])
  const [companyID, setCompanyID] = useState(null)
  const [importType, setImportType] = useState({
    id: '',
    name: '',
    icon: null,
    data: [],
    validation: () => {},
  })
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState({})

  OAuthClient.callback()
  const { token } = useToken()
  const { t } = useTranslation()

  useEffect(() => {
    if (!token) {
      setUser({})
      setCompanies([])
      return
    }
    getCurrentUser().then((currentUser) => {
      if (!currentUser?.perms.includes('admin')) {
        OAuthClient.wipeTokens()
        window.location.reload()
        return
      }
      setUser(currentUser)
    })
    getCompanies().then((companies) => {
      setCompanies(companies)
      setLoading(false)
    })
  }, [token])

  const prevStep = () => {
    setStep((prev) => prev - 1)
  }

  const nextStep = () => {
    setStep((prev) => prev + 1)
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <CompanySelect
            nextStep={nextStep}
            loading={loading}
            setLoading={setLoading}
            companyID={companyID}
            setCompanyID={setCompanyID}
            companies={companies}
            setCompanies={setCompanies}
          />
        )
      case 2:
        return (
          <Import
            nextStep={nextStep}
            prevStep={prevStep}
            importType={importType}
            companyID={companyID}
            companies={companies}
            setImportType={setImportType}
          />
        )
      default:
    }
  }

  return (
    <ChakraProvider theme={theme}>
      <Header user={user} />
      <Container maxW='container.md'>
        <Flex
          alignItems='center'
          direction='column'
          justifyContent='center'
          style={{ height: '80vh' }}
        >
          <Center>
            <Heading as='h1' size='2xl' color='#142341'>
              {t('app_name')}
            </Heading>
          </Center>
          <Box
            bg='#ffffff'
            w='100%'
            border='1px solid #c8cdd2'
            h='auto'
            margin={10}
            borderRadius={5}
            p={4}
            color='gray.700'
          >
            {renderStep()}
          </Box>
        </Flex>
      </Container>
    </ChakraProvider>
  )
}

export default Welcome
