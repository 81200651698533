import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import 'virtual:vite-plugin-sentry/sentry-config'

import App from './App'

const { dist } = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG
const { release } = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG

Sentry.init({
  dsn: 'https://600dcdc237dc41aa80c786fc2f864b4c@sentry.thetreep.com/4',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  dist,
  release,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
