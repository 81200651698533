import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  ListItem,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  UnorderedList,
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCompanyEntities } from '../../services/entityService'
import { getCompanyServices } from '../../services/serviceService'
import { getCompanyUsersCount } from '../../services/userService'

const CompanySelect = ({
  nextStep,
  loading,
  companyID,
  setCompanyID,
  companies,
}) => {
  const [companyApprovalMode, setCompanyApprovalMode] = useState('basic')
  const [companyConfigValid, setCompanyConfigValid] = useState(true)
  const [companyUserAnalytics, setCompanyUserAnalytics] = useState({})
  const [companyUsersCount, setCompanyUsersCount] = useState({
    loading: false,
    data: 0,
  })
  const [companyEntities, setCompanyEntities] = useState({
    loading: false,
    data: [],
  })
  const [companyServices, setCompanyServices] = useState({
    loading: false,
    data: [],
  })

  const { t } = useTranslation('translation', {
    keyPrefix: 'steps.select_company',
  })

  const rsOptions = companies
    .map((company) => {
      return {
        label: company.name,
        value: company.uid,
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const checkCompanyProfile = (company) => {
    if (company.config?.sabrePaxCustomerProfile) {
      return (
        company.config.sabrePaxCustomerProfile.number &&
        company.config.sabrePaxCustomerProfile.pcc &&
        company.config.sabrePaxCustomerProfile.type
      )
    } else if (company.config?.amadeusCustomerProfile) {
      return (
        company.config.amadeusCustomerProfile.officeID &&
        company.config.amadeusCustomerProfile.uniqueID
      )
    }

    return false
  }

  const getCompanyUserAnalytics = (company) => company.user_analytics || {}

  const handleCompanySelect = (e) => {
    if (!e) {
      setCompanyID(0)
      return
    }

    const company = companies.find((c) => c.uid === parseInt(e.value))

    setCompanyUserAnalytics(getCompanyUserAnalytics(company))
    setCompanyConfigValid(checkCompanyProfile(company))
    setCompanyApprovalMode(company?.config?.approvalType || 'basic')
    setCompanyID(parseInt(e.value))
  }

  const confirm = (e) => {
    e.preventDefault()
    nextStep()
  }

  useEffect(() => {
    if (companyID) {
      handleCompanySelect({ value: companyID })
      setCompanyUsersCount({ loading: true, data: 0 })
      setCompanyEntities({ loading: true, data: [] })
      setCompanyServices({ loading: true, data: [] })
      getCompanyUsersCount(companyID).then((usersCount) => {
        setCompanyUsersCount({ loading: false, data: usersCount })
      })
      getCompanyEntities(companyID).then((entities) => {
        setCompanyEntities({ loading: false, data: entities })
      })
      getCompanyServices(companyID).then((services) => {
        setCompanyServices({ loading: false, data: services })
      })
    }
  }, [companyID])

  return (
    <>
      <Center>
        <Heading mb='1rem' as='h4' size='sm'>
          {t('title')} :
        </Heading>
      </Center>
      <Flex>
        <div style={{ width: '100%' }}>
          <Select
            size='md'
            placeholder={t('placeholder')}
            onChange={(e) => setCompanyID(parseInt(e.value))}
            isDisabled={loading}
            value={rsOptions.find((c) => c.value === companyID) || 0}
            options={rsOptions}
            selectedOptionStyle='check'
          />
        </div>
        <Button
          variant='solid'
          isLoading={loading}
          isDisabled={!companyID}
          color='black'
          backgroundColor='#afdc00'
          onClick={confirm}
          _hover={{ bg: '#9bc300' }}
          _active={{ bg: '#81a20c' }}
          ml='1rem'
        >
          {t('confirm')}
        </Button>
      </Flex>
      {companyID > 0 && (
        <>
          <Flex color='white' mt='1rem' gap='1rem'>
            <Box
              bg='#f2f8fe'
              w='25%'
              borderRadius='5px'
              paddingX='16px'
              paddingY='12px'
            >
              <Stat color='#2D3748'>
                <StatLabel>{t('companyStats.users')}</StatLabel>
                {companyUsersCount.loading ? (
                  <Spinner mt='5px' />
                ) : (
                  <StatNumber>{companyUsersCount.data}</StatNumber>
                )}
              </Stat>
            </Box>
            <Box
              bg='#f2f8fe'
              w='25%'
              borderRadius='5px'
              paddingX='16px'
              paddingY='12px'
            >
              <Stat color='#2D3748'>
                <StatLabel>{t('companyStats.entities')}</StatLabel>
                {companyEntities.loading ? (
                  <Spinner mt='5px' />
                ) : (
                  <StatNumber>{companyEntities.data.length}</StatNumber>
                )}
              </Stat>
            </Box>
            <Box
              bg='#f2f8fe'
              w='25%'
              borderRadius='5px'
              paddingX='16px'
              paddingY='12px'
            >
              <Stat color='#2D3748'>
                <StatLabel>{t('companyStats.services')}</StatLabel>
                {companyServices.loading ? (
                  <Spinner mt='5px' />
                ) : (
                  <StatNumber>{companyServices.data.length}</StatNumber>
                )}
              </Stat>
            </Box>
            <Box
              bg='#f2f8fe'
              w='25%'
              borderRadius='5px'
              paddingX='16px'
              paddingY='12px'
            >
              <Stat color='#2D3748'>
                <StatLabel>{t('companyStats.approvalType')}</StatLabel>
                <StatNumber>
                  {t(`companyStats.approvalTypes.${companyApprovalMode}`)}
                </StatNumber>
              </Stat>
            </Box>
          </Flex>
        </>
      )}
      {!companyConfigValid && (
        <Box mt='1rem' borderRadius='5px' overflow='hidden'>
          <Alert status='warning'>
            <AlertIcon />
            <Box>
              <AlertTitle>{t('alerts.incorrectGDSProfile.title')} !</AlertTitle>
              <AlertDescription>
                {t('alerts.incorrectGDSProfile.text')}.
              </AlertDescription>
            </Box>
          </Alert>
        </Box>
      )}
      {companyApprovalMode === 'hierarchical' && (
        <Box mt='1rem' borderRadius='5px' overflow='hidden'>
          <Alert status='info'>
            <AlertIcon />
            <Box>
              <AlertTitle>{t('alerts.hierarchicalApproval.title')}</AlertTitle>
              <AlertDescription>
                {t('alerts.hierarchicalApproval.text')}.
              </AlertDescription>
            </Box>
          </Alert>
        </Box>
      )}
      {companyUserAnalytics.length > 0 && (
        <Box mt='1rem' borderRadius='5px' overflow='hidden'>
          <Alert status='info'>
            <AlertIcon />
            <Box>
              <AlertTitle>{t('alerts.companyUserAnalytics.title')}</AlertTitle>
              <AlertDescription>
                {t('alerts.companyUserAnalytics.text')}
                <UnorderedList>
                  {companyUserAnalytics.map((analytic) => (
                    <ListItem key={analytic.uid}>
                      {analytic.label}{' '}
                      {analytic.key && <Badge>GDS: {analytic.key}</Badge>}{' '}
                      {analytic.required && (
                        <Badge colorScheme='red'>
                          {t('alerts.companyUserAnalytics.tags.required')}
                        </Badge>
                      )}{' '}
                      {analytic.is_gdpr_sensitive && (
                        <Badge colorScheme='purple'>
                          {t('alerts.companyUserAnalytics.tags.sensitiveData')}
                        </Badge>
                      )}
                    </ListItem>
                  ))}
                </UnorderedList>
              </AlertDescription>
            </Box>
          </Alert>
        </Box>
      )}
    </>
  )
}

export default CompanySelect
