import { Fetcher } from 'jso'
import OAuthClient from '../global/auth'

export const getCards = async () => {
  const f = new Fetcher(OAuthClient)
  return f
    .fetch(`${import.meta.env.VITE_THETREEP_API_URL}/user/cards/types`, {
      method: 'GET',
    })
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText)
      }
      return response.json()
    })
    .then(({ result }) => {
      return result
    })
}
