import { JSO } from 'jso'

const OAuthClient = new JSO({
  providerID: 'TheTreep',
  client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
  redirect_uri: import.meta.env.VITE_APP_URL,
  authorization: import.meta.env.VITE_OAUTH_AUTHORIZATION,
  debug: false,
  response_type: 'token',
})

export default OAuthClient
