import dayjs from 'dayjs'
import { getUserByUsername, postUserCard } from '../services/userService'
import { frenchDateToAPI } from './utils/utils'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const cardsImportHandler = async (card) => {
  const user = await getUserByUsername(card.username)

  // eslint-disable-next-line no-unused-vars
  const { username, cardUID, ...cardObject } = card

  const sanitizedCard = {
    card: {
      ...cardObject,
      user_id: user.uid,
      cardtype_id: cardUID,
      effective_date: frenchDateToAPI(card?.effective_date),
      expiration_date: card?.expiration_date
        ? frenchDateToAPI(card?.expiration_date)
        : dayjs(card?.effective_date, 'DD/MM/YYYY')
            .add(1, 'year')
            .format('YYYYMMDDT120000'),
    },
  }

  return postUserCard(sanitizedCard)
}

export default cardsImportHandler
