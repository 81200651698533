async function* runTasks(maxConcurrency, taskIterator) {
  // Each async iterator is a worker, polling for tasks from the shared taskIterator
  // Sharing the iterator ensures that each worker gets unique tasks.
  const asyncIterators = new Array(maxConcurrency)
  for (let i = 0; i < maxConcurrency; i++) {
    asyncIterators[i] = (async function* () {
      for (const task of taskIterator) yield await task()
    })()
  }
  yield* raceAsyncIterators(asyncIterators)
}

async function* raceAsyncIterators(asyncIterators) {
  async function nextResultWithItsIterator(iterator) {
    return { result: await iterator.next(), iterator }
  }

  /** @type Map<AsyncIterator<T>,
   Promise<{result: IteratorResult<T>, iterator: AsyncIterator<T>}>> */
  const promises = new Map(
    asyncIterators.map((iterator) => [
      iterator,
      nextResultWithItsIterator(iterator),
    ]),
  )
  while (promises.size) {
    const { result, iterator } = await Promise.race(promises.values())
    if (result.done) {
      promises.delete(iterator)
    } else {
      promises.set(iterator, nextResultWithItsIterator(iterator))
      yield result.value
    }
  }
}

export default runTasks
