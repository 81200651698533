import { Fetcher } from 'jso'
import OAuthClient from '../global/auth'

export const getCompanyUsers = async (companyID) => {
  const f = new Fetcher(OAuthClient)
  const url = `${
    import.meta.env.VITE_THETREEP_API_URL
  }/users/?${new URLSearchParams({
    'disable-hiding': 'true',
    full: 'false',
    limit: '20000',
    offset: '0',
    order_by: 'ASC',
    company_id: companyID,
    sort_by: 'id',
  })}`
  const response = await f.fetch(url, {})
  const responseObject = await response.json()
  return responseObject.result || []
}

export const getUserByUsername = async (username) => {
  const f = new Fetcher(OAuthClient)
  const url = `${
    import.meta.env.VITE_THETREEP_API_URL
  }/users/?${new URLSearchParams({
    'disable-hiding': 'true',
    full: 'true',
    username,
  })}`

  const response = await f.fetch(url, {})
  const responseObject = await response.json()

  if (responseObject.result?.length > 0) {
    return responseObject.result[0]
  }
  return []
}

export const getCompanyUsersCount = async (companyID) => {
  const f = new Fetcher(OAuthClient)
  const url = `${
    import.meta.env.VITE_THETREEP_API_URL
  }/users/?${new URLSearchParams({
    'disable-hiding': 'true',
    full: 'false',
    limit: '1',
    offset: '0',
    order_by: 'ASC',
    company_id: companyID,
    sort_by: 'id',
  })}`
  const response = await f.fetch(url, {})
  const responseObject = await response.json()
  return responseObject.total || 0
}

export const getCurrentUser = () => {
  const f = new Fetcher(OAuthClient)
  return f
    .fetch(`${import.meta.env.VITE_THETREEP_API_URL}/user/`, {
      method: 'GET',
    })
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText)
      }
      return response.json()
    })
    .then(({ result }) => {
      return result
    })
}

export const setApprovees = (userID, approvees) => {
  const f = new Fetcher(OAuthClient)
  return f
    .fetch(
      `${import.meta.env.VITE_THETREEP_API_URL}/user/${userID}/approvees`,
      {
        method: 'PATCH',
        body: JSON.stringify({ userIDs: approvees }),
      },
    )
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        return response.text().then((res) => {
          throw new Error(res)
        })
      } else {
        return response.json()
      }
    })
    .catch((error) => {
      throw new Error(error)
    })
}

export const updateUser = (user) => {
  const f = new Fetcher(OAuthClient)
  return f
    .fetch(`${import.meta.env.VITE_THETREEP_API_URL}/admin/user/${user.uid}`, {
      method: 'PUT',
      body: JSON.stringify(user),
    })
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        return response.text().then((res) => {
          throw new Error(res)
        })
      } else {
        return response.json()
      }
    })
    .catch((error) => {
      throw new Error(error)
    })
}

export const postUser = (user) => {
  const f = new Fetcher(OAuthClient)
  return f
    .fetch(`${import.meta.env.VITE_THETREEP_API_URL}/admin/user`, {
      method: 'POST',
      body: JSON.stringify(user),
    })
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        return response.text().then((res) => {
          throw new Error(res)
        })
      } else {
        return response.json()
      }
    })
    .catch((error) => {
      throw new Error(error)
    })
}

export const postUserDocument = (document) => {
  const f = new Fetcher(OAuthClient)
  return f
    .fetch(`${import.meta.env.VITE_THETREEP_API_URL}/user/document`, {
      method: 'POST',
      body: JSON.stringify(document),
    })
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        return response.text().then((res) => {
          throw new Error(res)
        })
      } else {
        return response.json()
      }
    })
    .catch((error) => {
      throw new Error(error)
    })
}

export const postUserCard = (card) => {
  const f = new Fetcher(OAuthClient)
  return f
    .fetch(`${import.meta.env.VITE_THETREEP_API_URL}/user/card`, {
      method: 'POST',
      body: JSON.stringify(card),
    })
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        return response.text().then((res) => {
          throw new Error(res)
        })
      } else {
        return response.json()
      }
    })
    .catch((error) => {
      throw new Error(error)
    })
}
