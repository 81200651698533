import OAuthClient from '../global/auth'
import { useEffect, useState } from 'react'

export const useToken = () => {
  const [token, setToken] = useState()
  const getToken = () => {
    OAuthClient.getToken({})
      .then((response) => {
        setToken(response.access_token)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getToken()
  }, [])

  return { token, getToken }
}
