import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

function NotImplemented({ isOpen, onClose }) {
  const cancelRef = useRef()
  const { t } = useTranslation('translation', {
    keyPrefix: 'steps.import.notImplemented',
  })

  const handleConfirm = (e) => {
    window.open('mailto:help@thetreep.com')
    onClose(e)
  }
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {t('title')}
            </AlertDialogHeader>

            <AlertDialogBody>{t('text')}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button
                variant='solid'
                color='black'
                backgroundColor='#afdc00'
                onClick={handleConfirm}
                ml={3}
                _hover={{ bg: '#9bc300' }}
                _active={{ bg: '#81a20c' }}
              >
                {t('confirm')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default NotImplemented
