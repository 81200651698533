import { getCards } from '../services/cardService'

const cardFields = [
  {
    label: "Nom d'utilisateur",
    key: 'username',
    alternateMatches: ["Nom d'utilisateur", 'utilisateur', 'login', 'LOGIN'],
    fieldType: {
      type: 'input',
    },
    example: 'jean.durand@thetreep.com',
    validations: [
      {
        rule: 'regex',
        value: '^[A-Za-z][A-Za-z0-9_.@-]{3,255}$',
        errorMessage:
          "Le format de nom d'utilisateur est non valide. Il doit comprendre au minimum 4 caractères, et ne peut contenir que les caractères spéciaux suivants: _, ., @, -",
        level: 'error',
      },
      {
        rule: 'required',
        errorMessage: "Le nom d'utilisateur est requis",
        level: 'error',
      },
    ],
  },
  {
    label: 'Nom de famille',
    key: 'lastname',
    alternateMatches: ['nom', 'nom de famille', 'LAST_NAME'],
    fieldType: {
      type: 'input',
    },
    example: 'Durand',
  },
  {
    label: 'Prénom',
    key: 'firstname',
    alternateMatches: ['prénom', 'prenom', 'FIRST_NAME'],
    fieldType: {
      type: 'input',
    },
    example: 'Jean',
  },
  {
    label: 'Numéro',
    key: 'cardnumber',
    alternateMatches: ['numéro', 'number'],
    fieldType: {
      type: 'input',
    },
    example: '911091204209',
  },
  {
    label: "Date d'effectivité",
    key: 'effective_date',
    alternateMatches: ["date d'effet", 'date', "date d'effectivité"],
    fieldType: {
      type: 'input',
    },
    example: '25/02/2006',
    validations: [
      {
        rule: 'regex',
        value:
          '(^$)|(^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[012])[-/.](19|20)\\d\\d$)',
        errorMessage: 'La date doit être au format DD/MM/YYYY',
        level: 'error',
      },
    ],
  },
  {
    label: "Date d'expiration",
    key: 'expiration_date',
    alternateMatches: ["date d'expiration", 'date de peremption'],
    fieldType: {
      type: 'input',
    },
    example: '25/02/2006',
    validations: [
      {
        rule: 'regex',
        value:
          '(^$)|(^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[012])[-/.](19|20)\\d\\d$)',
        errorMessage: 'La date doit être au format DD/MM/YYYY',
        level: 'error',
      },
    ],
  },
  {
    label: 'Nom',
    key: 'card_name',
    alternateMatches: ['nom du document'],
    fieldType: {
      type: 'input',
    },
    example: 'Carte abo SNCF',
  },
]

const decoratedCardFields = async () => {
  const cardTypes = await getCards()
  const mappedTypes = cardTypes.map((cardType) => {
    return {
      label: cardType.name,
      value: cardType.uid,
    }
  })
  return [
    ...cardFields,
    {
      label: 'Type de carte',
      key: 'cardUID',
      alternateMatches: ['type', 'carte'],
      fieldType: {
        type: 'select',
        options: mappedTypes,
      },
      example: 'Carte liberté (SNCF)',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Le type de carte est requis',
          level: 'error',
        },
      ],
    },
  ]
}

export { cardFields, decoratedCardFields }
