import parsePhoneNumber from 'libphonenumber-js/min'
import {
  getCompanyUsers,
  getUserByUsername,
  postUser,
  setApprovees,
  updateUser,
} from '../services/userService'
import passwordGenerator from './utils/passwordGenerator'
import { frenchDateToAPI, roleToPermission } from './utils/utils'

const usersImportHandler = async (user, company) => {
  const userData = {
    ...user,
    password: passwordGenerator(),
    approval_level: parseInt(user?.approval_level, 10) || 0,
    company_id: company.uid,
    auth_by_company: company.uid && company.uid > 0 ? true : null,
    birthdate: frenchDateToAPI(user?.birthdate),
    permission: roleToPermission(user.role),
    analytics: [],
    approvers: [],
  }

  if (userData.phone) {
    const formattedPhone = parsePhoneNumber(userData.phone)?.number
    if (formattedPhone) {
      userData.phone = formattedPhone
    }
  }

  if (company.user_analytics) {
    company.user_analytics.forEach((analytic) => {
      const userAnalytic = {
        analytic,
        value: user[analytic.uid],
      }
      userData.analytics.push(userAnalytic)
      delete userData[analytic.uid]
    })
  }

  // TODO: Replace this check with a call to the new check-user endpoint when available
  const maybeExistingUser = await getUserByUsername(user.username)
  if (maybeExistingUser.uid) {
    if (maybeExistingUser.company_id !== company.uid) {
      throw new Error(
        `L'utilisateur ${user.username} existe déjà dans une autre entreprise. Merci de bien vouloir le supprimer de l'entreprise ${maybeExistingUser.company_id} avant de l'importer dans l'entreprise ${company.uid}`,
      )
    }
    // User already exists, updating instead. We also strip things we do not
    // want to override, such as the password.
    // eslint-disable-next-line no-unused-vars
    const { password, ...userToUpdate } = userData
    return updateUser({
      ...userToUpdate,
      uid: maybeExistingUser.uid,
      approval_level:
        parseInt(user?.approval_level, 10) ||
        maybeExistingUser.approval_level ||
        0,
    })
  }

  return postUser(userData)
}

const postUsersImportHandler = async (data, company, setErrors) => {
  if (company.config?.approvalType === 'hierarchical') {
    const companyUsers = await getCompanyUsers(company.uid)
    const usersbyApprover = data.validData.reduce((acc, d) => {
      if (acc[d.approvers] || d.approvers === '') {
        return acc
      }
      acc[d.approvers] = data.validData.filter(
        (g) => g.approvers === d.approvers,
      )
      return acc
    }, {})

    Object.entries(usersbyApprover).forEach((userGroup) => {
      const [key, value] = userGroup
      const approver = companyUsers.find((u) => u.username === key)
      if (!approver) {
        return
      }
      const approveesUIDs = value.map((approvee) => {
        return companyUsers.find((u) => u.username === approvee.username)?.uid
      })
      setApprovees(approver?.uid, approveesUIDs).catch((error) => {
        setErrors((prevErrors) => [
          ...prevErrors,
          { response: error, approver },
        ])
      })
    })
  }
}

export { usersImportHandler, postUsersImportHandler }
