import {
  Button,
  Center,
  chakra,
  Divider,
  Flex,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { AiFillHome, AiOutlineLogout } from 'react-icons/ai'

import TheTreepLogo from '../../assets/img/THE_TREEP_LOGO_BLUE.svg'
import { useTranslation } from 'react-i18next'
import OAuthClient from '../../global/auth'

const Header = (user) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'header',
  })
  return (
    <React.Fragment>
      <chakra.header
        bg='#142341'
        w='full'
        px={{
          base: 2,
          sm: 4,
        }}
        py={4}
        shadow='md'
      >
        <Flex alignItems='center' justifyContent='space-between' mx='auto'>
          <HStack display='flex' spacing={3} alignItems='center'>
            <chakra.a
              href='/'
              title={t('home')}
              display='flex'
              alignItems='center'
            >
              <Image
                src={TheTreepLogo}
                htmlWidth='200px'
                fit='cover'
                height='40px'
              />
            </chakra.a>

            <HStack
              spacing={3}
              display={{
                base: 'none',
                md: 'inline-flex',
              }}
            >
              <Button
                variant='ghost'
                leftIcon={<AiFillHome />}
                size='sm'
                colorScheme='whiteAlpha'
              >
                {t('import')}
              </Button>
            </HStack>
          </HStack>
          {user.user.username && (
            <HStack spacing={3} display={'flex'} alignItems='center'>
              <Text color='white'>
                {t('logged_in_as')} {user.user.username}
              </Text>
              <Center height='1rem'>
                <Divider orientation='vertical' colorScheme='whiteAlpha' />
              </Center>
              <Button
                variant='outline'
                leftIcon={<AiOutlineLogout />}
                size='sm'
                colorScheme='whiteAlpha'
                onClick={() => {
                  OAuthClient.wipeTokens()
                  window.location.reload()
                }}
              >
                Logout
              </Button>
            </HStack>
          )}
        </Flex>
      </chakra.header>
    </React.Fragment>
  )
}

export default Header
