import countries from './countries'

const documentFields = [
  {
    label: "Nom d'utilisateur",
    key: 'username',
    alternateMatches: ["Nom d'utilisateur", 'utilisateur', 'login', 'LOGIN'],
    fieldType: {
      type: 'input',
    },
    example: 'jean.durand@thetreep.com',
    validations: [
      {
        rule: 'regex',
        value: '^[A-Za-z][A-Za-z0-9_.@-]{3,255}$',
        errorMessage:
          "Le format de nom d'utilisateur est non valide. Il doit comprendre au minimum 4 caractères, et ne peut contenir que les caractères spéciaux suivants: _, ., @, -",
        level: 'error',
      },
      {
        rule: 'required',
        errorMessage: "Le nom d'utilisateur est requis",
        level: 'error',
      },
    ],
  },
  {
    label: 'Type de document',
    key: 'type',
    alternateMatches: [
      'Type',
      'document',
      'passeport',
      "carte d'identité",
      'permis',
      'driving permit',
      'driving license',
      'id card',
      'passport',
    ],
    fieldType: {
      type: 'select',
      options: [
        { value: 'driver_license', label: 'Permis de conduire' },
        {
          value: 'id_card',
          label: "Carte d'identité",
        },
        {
          value: 'passport',
          label: 'Passeport',
        },
      ],
    },
    example: 'Permis de conduire',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Le type de document est requis',
        level: 'error',
      },
    ],
  },
  {
    label: 'Civilité',
    key: 'civility',
    alternateMatches: ['civilité', 'civilite', 'genre', 'sexe', 'PREFIX'],
    fieldType: {
      type: 'select',
      options: [
        { label: 'M', value: 'MR' },
        { label: 'MME', value: 'MS' },
      ],
    },
    example: 'M',
    validations: [
      {
        rule: 'required',
        errorMessage: 'la civilité est requise',
        level: 'error',
      },
    ],
  },
  {
    label: 'Nom de famille',
    key: 'lastname',
    alternateMatches: ['nom', 'nom de famille', 'LAST_NAME'],
    fieldType: {
      type: 'input',
    },
    example: 'Durand',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Le nom de famille est requis',
        level: 'error',
      },
    ],
  },
  {
    label: 'Prénom',
    key: 'firstname',
    alternateMatches: ['prénom', 'prenom', 'FIRST_NAME'],
    fieldType: {
      type: 'input',
    },
    example: 'Jean',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Le prénom est requis',
        level: 'error',
      },
    ],
  },
  {
    label: 'Numéro',
    key: 'number',
    alternateMatches: ['numéro', 'number'],
    fieldType: {
      type: 'input',
    },
    example: '911091204209',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Le numéro est requis',
        level: 'error',
      },
    ],
  },
  {
    label: "Date d'effectivité",
    key: 'effective_date',
    alternateMatches: ["date d'effet", 'date', "date d'effectivité"],
    fieldType: {
      type: 'input',
    },
    example: '25/02/2006',
    validations: [
      {
        rule: 'regex',
        value:
          '(^$)|(^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[012])[-/.](19|20)\\d\\d$)',
        errorMessage: 'La date doit être au format DD/MM/YYYY',
        level: 'error',
      },
      {
        rule: 'required',
        errorMessage: 'Le numéro est requis',
        level: 'error',
      },
    ],
  },
  {
    label: 'Pays du document',
    key: 'country_of_issue',
    alternateMatches: ['pays', 'country', 'country of issue'],
    fieldType: {
      type: 'select',
      options: countries,
    },
    example: 'France',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Le pays est requis',
        level: 'error',
      },
    ],
  },
  {
    label: 'Nationalité',
    key: 'nationality',
    alternateMatches: ['nationalité', 'country', 'pays de naissance'],
    fieldType: {
      type: 'select',
      options: countries,
    },
    example: 'France',
    validations: [
      {
        rule: 'required',
        errorMessage: 'La nationalité du voyageur est requise',
        level: 'error',
      },
    ],
  },
  {
    label: "Date d'expiration",
    key: 'expiration_date',
    alternateMatches: ["date d'expiration", 'date de peremption'],
    fieldType: {
      type: 'input',
    },
    example: '25/02/2006',
    validations: [
      {
        rule: 'regex',
        value:
          '(^$)|(^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[012])[-/.](19|20)\\d\\d$)',
        errorMessage: 'La date doit être au format DD/MM/YYYY',
        level: 'error',
      },
    ],
  },
  {
    label: 'Nom',
    key: 'document_name',
    alternateMatches: ['nom du document'],
    fieldType: {
      type: 'input',
    },
    example: 'CNI France',
  },
]

export { documentFields }
