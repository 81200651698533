import './global/i18n/i18n'
import { Suspense } from 'react'
import Welcome from './pages/Welcome'

export default function App() {
  return (
    <Suspense fallback=''>
      <Welcome />
    </Suspense>
  )
}
