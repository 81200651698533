import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  Center,
  Fade,
  Flex,
  Heading,
  Icon,
  Progress,
  Spinner,
  Text,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ReactSpreadsheetImport } from '@thetreep/react-spreadsheet-import'
import { RSIThemeOverrides } from '../../global/theme'

import ImportTypes from '../../global/types'
import { en, fr } from '../../global/i18n/RSITranslations'
import { useNavigatorLanguage } from 'rooks'
import { AiOutlineCheck, AiOutlineWarning } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import runTasks from '../../global/utils/taskThrottler'
import NotImplemented from '../NotImplemented'

const Import = ({
  prevStep,
  setImportType,
  companyID,
  companies,
  importType,
}) => {
  const [open, setOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [uploadDone, setUploadDone] = useState(false)
  const [errors, setErrors] = useState([])
  const [maxProgress, setMaxProgress] = useState(0)
  const [progress, setProgress] = useState(0)
  const [progressPercent, setProgressPercent] = useState(0)
  const [fields, setFields] = useState([])
  const company = companies.find((c) => c.uid === companyID)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const confirm = async (it) => {
    if (!it.data.length) {
      onOpen()
      return
    }

    if (typeof it.decorated === 'function') {
      setFields(await it.decorated(company))
    } else {
      setFields(it.data)
    }

    setImportType(it)
    setOpen(true)
  }

  const language = useNavigatorLanguage().slice(0, 2) === 'fr' ? fr : en
  const { t } = useTranslation('translation', {
    keyPrefix: 'steps.import',
  })

  useEffect(() => {
    setProgressPercent((progress / maxProgress) * 100)
  }, [progress, maxProgress])

  // The handler makes use of async generator functions. The main purpose is
  // to "race" pools of promises, which is superior to batching, since we do not
  // need to wait for every promise in a batch to resolve before doing
  // something with it.
  const uploadHandler = (data) => {
    setUploading(true)

    const dataToImport =
      typeof importType.batcher === 'function'
        ? importType.batcher(data)
        : data.validData

    // Reset upload state in case the user is uploading data back to back, but keep errors
    setProgress(0)
    setUploadDone(false)
    setProgress(0)
    setMaxProgress(dataToImport.length)
    setErrors([])

    const uploadFunc = async (validData, i) => {
      const currentData = validData[i]
      return importType.uploadHandler(currentData, company)
    }

    const tasks = []
    for (let i = 0; i < dataToImport.length; i++) {
      tasks.push(async () => {
        try {
          await uploadFunc(dataToImport, i)
        } catch (e) {
          setMaxProgress((prev) => prev - 1)
          setErrors((prevErrors) => [
            ...prevErrors,
            { response: e, user: dataToImport[i] },
          ])
        }
        return dataToImport[i]
      })
    }

    ;(async () => {
      // eslint-disable-next-line no-unused-vars
      for await (const value of runTasks(4, tasks.values())) {
        setProgress((prev) => prev + 1)
      }
    })().then(async () => {
      setUploadDone(true)
      if (typeof importType.postUploadHook === 'function') {
        await importType.postUploadHook(data, company, setErrors)
      }
    })
  }

  return (
    <>
      <ReactSpreadsheetImport
        isOpen={open}
        onClose={() => {
          setOpen(false)
        }}
        onSubmit={uploadHandler}
        fields={fields}
        translations={language}
        customTheme={RSIThemeOverrides}
      />
      <Center>
        <NotImplemented isOpen={isOpen} onClose={onClose} />
        <Heading mb='1rem' as='h4' size='sm'>
          {t('dataTypePromptHeading')} :
        </Heading>
      </Center>
      <Flex
        css={{ gap: '1rem' }}
        wrap='wrap'
        direction='row'
        w='full'
        alignItems='stretch'
      >
        {Object.values(ImportTypes).map(
          (it) =>
            it.requirements(company) && (
              <Button
                flexGrow='1'
                pt={9}
                pb={9}
                key={it.id}
                textAlign='center'
                variant='outline'
                flexDir='column'
                justifyContent='center'
                onClick={async () => await confirm(it)}
              >
                <Icon as={it.icon} boxSize={6} />
                <Text
                  as='span'
                  mt={3}
                  fontSize='sm'
                  fontWeight='normal'
                  textAlign='center'
                >
                  {it.name}
                </Text>
              </Button>
            ),
        )}
      </Flex>
      {uploading && (
        <>
          <Box
            bg='#ffffff'
            border='1px solid #c8cdd2'
            h='auto'
            borderRadius={5}
            mt='1rem'
            p={4}
            color='gray.700'
          >
            <Flex
              css={{ gap: '1rem' }}
              wrap='nowrap'
              direction='row'
              w='full'
              alignItems='center'
              justifyContent='center'
            >
              <Progress
                w='full'
                colorScheme='gray'
                size='xs'
                value={progressPercent}
              />
              {!uploadDone && (
                <Fade in={uploading}>
                  <Spinner />
                </Fade>
              )}
              {uploadDone && (
                <Fade in={uploadDone}>
                  <Icon
                    as={errors.length > 0 ? AiOutlineWarning : AiOutlineCheck}
                  />
                </Fade>
              )}
              <div style={{ width: '10%', textAlign: 'center' }}>
                {progress}/{maxProgress}
              </div>
            </Flex>
          </Box>
          {errors.length > 0 && (
            <Box
              maxH={300}
              border='2px solid #A5506E'
              borderRadius={5}
              overflowY='scroll'
              mt='1rem'
            >
              <Alert status='error'>
                <AlertIcon />
                <AlertTitle>{t('errors.alert.title')}</AlertTitle>
                <AlertDescription>{t('errors.alert.text')}</AlertDescription>
              </Alert>
              <Accordion allowMultiple allowToggle>
                {errors.map((error, i) => (
                  <AccordionItem key={i}>
                    <h2>
                      <AccordionButton>
                        <Box flex='1' textAlign='left'>
                          {error?.user?.username ?? `#${i}`}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      {error.response.toString()}
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          )}
        </>
      )}
      <Button mt='1rem' onClick={prevStep} variant='outline'>
        {t('goBack')}
      </Button>
    </>
  )
}

export default Import
