const entityFields = [
  {
    label: "Nom d'utilisateur",
    key: 'username',
    alternateMatches: ["Nom d'utilisateur", 'utilisateur', 'login', 'LOGIN'],
    fieldType: {
      type: 'input',
    },
    example: 'jean.durand@thetreep.com',
    validations: [
      {
        rule: 'regex',
        value: '^[A-Za-z][A-Za-z0-9_.@-]{3,255}$',
        errorMessage:
          "Le format de nom d'utilisateur est non valide. Il doit comprendre au minimum 4 caractères, et ne peut contenir que les caractères spéciaux suivants: _, ., @, -",
        level: 'error',
      },
      {
        rule: 'required',
        errorMessage: "Le nom d'utilisateur est requis",
        level: 'error',
      },
    ],
  },
]

const withEntities = (company) => {
  const entitiesOptions = company.entities.map((entity) => {
    return {
      value: entity.uid,
      label: entity.name,
    }
  })

  return [
    {
      label: 'Entité',
      key: 'entity',
      alternateMatches: ['Entité', 'entite', 'entity'],
      fieldType: {
        type: 'select',
        options: entitiesOptions,
      },
      example: entitiesOptions[0]?.name,
      validations: [
        {
          rule: 'required',
          errorMessage: 'Ce champ ne peut pas être vide',
          level: 'error',
        },
      ],
    },
  ]
}

const decoratedEntityFields = (company) => {
  const af = [...entityFields]
  if (company.entities.length > 0) {
    af.push(...withEntities(company))
  }
  return af
}

export { entityFields, decoratedEntityFields, withEntities }
