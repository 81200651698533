import { Fetcher } from 'jso'
import OAuthClient from '../global/auth'

export const getCompanyServices = async (companyID) => {
  const f = new Fetcher(OAuthClient)
  const url = `${
    import.meta.env.VITE_THETREEP_API_URL
  }/service/?${new URLSearchParams({
    full: 'true',
    limit: '2000',
    offset: '0',
    order_by: 'ASC',
    company_id: companyID,
    sort_by: 'id',
  })}`
  const response = await f.fetch(url, {})
  const responseObject = await response.json()
  return responseObject.result || []
}
