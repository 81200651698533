import { setUserEntities } from '../services/entityService'
import { getUserByUsername } from '../services/userService'

const entitiesImportHandler = async (entityGroups) => {
  const user = await getUserByUsername(entityGroups.username)
  return setUserEntities(user, entityGroups.entities)
}

const entitiesImportBatcher = (data) => {
  //Regrouping again after grouping entities by user, so that we can batch
  //on users who have the entities assigned could be nice, but at this stage
  //we do not know which entities they are already assigned to, so we cannot
  //do this
  const entityGroupsAsObject = data.validData.reduce((acc, d) => {
    if (acc[d.username] || d.username === '') {
      return acc
    }
    acc[d.username] = data.validData.filter((g) => g.username === d.username)
    return acc
  }, {})

  const entityGroupsAsArray = []
  Object.entries(entityGroupsAsObject).forEach((entityGroup) => {
    const [key, value] = entityGroup
    entityGroupsAsArray.push({ username: key, entities: value })
  })

  return entityGroupsAsArray
}

export { entitiesImportHandler, entitiesImportBatcher }
