import { RiUserStarLine } from 'react-icons/ri'
import i18n from '../global/i18n/i18n'
import { AiOutlineUser } from 'react-icons/ai'
import { BiBuildingHouse } from 'react-icons/bi'
import { BsClipboardData } from 'react-icons/bs'
import { HiOutlineCreditCard, HiOutlineIdentification } from 'react-icons/hi'
import { decoratedAnalyticFields, analyticFields } from './analyticFields'
import analyticsImportHandler from './analyticsImportHandler'
import { approversFields } from './approversFields'
import {
  approversImportBatcher,
  approversImportHandler,
} from './approversImportHandler'
import { cardFields, decoratedCardFields } from './cardFields'
import cardsImportHandler from './cardsImportHandler'
import { documentFields } from './documentFields'
import documentsImportHandler from './documentsImportHandler'
import {
  entitiesImportBatcher,
  entitiesImportHandler,
} from './entitiesImportHandler'
import { decoratedEntityFields, entityFields } from './entityFields'

import { userFields, decoratedUserFields } from './userFields'
import {
  postUsersImportHandler,
  usersImportHandler,
} from './usersImportHandler'

// Hacky way of keeping translations up to date for data loaded asynchronously
const updateTranslations = () => {
  ImportTypes.User.name = i18n.t('importTypes.users')
  ImportTypes.Document.name = i18n.t('importTypes.documents')
  ImportTypes.Card.name = i18n.t('importTypes.cards')
  ImportTypes.Analytic.name = i18n.t('importTypes.analytics')
  ImportTypes.Entity.name = i18n.t('importTypes.entities')
  ImportTypes.Approvers.name = i18n.t('importTypes.approvers')
}

/**
 * An ImportType can take the following parameters :
 * id: string, the ID of the import type
 * name: string, translation of the import type name
 * icon: JSX Element, will be rendered above the import type name
 * data: object, in accordance with what RSI expects
 * requirements: (company) => bool, the import type will only be shown if this returns true
 * uploadHandler: (item, company) => promise, handler function for uploading one item (will be called multiple times)
 * postUploadHook: (item, company, setErrors) => promise, optional post upload step hook
 * decorated: (company) => RSI data object, optional function used to return altered fields depending on the company
 * batcher: (array) => array, optional function used to manipulate the original array of data (which is initially one item per line on the spreadsheet)
 */
const ImportTypes = {
  User: {
    id: 'user',
    name: i18n.t('importTypes.users'),
    icon: AiOutlineUser,
    data: userFields,
    requirements: () => true,
    uploadHandler: usersImportHandler,
    postUploadHook: postUsersImportHandler,
    decorated: (company) => {
      return decoratedUserFields(company)
    },
  },
  Document: {
    id: 'document',
    name: i18n.t('importTypes.documents'),
    icon: HiOutlineIdentification,
    data: documentFields,
    uploadHandler: documentsImportHandler,
    requirements: () => true,
  },
  Card: {
    id: 'card',
    name: i18n.t('importTypes.cards'),
    icon: HiOutlineCreditCard,
    data: cardFields,
    uploadHandler: cardsImportHandler,
    requirements: () => true,
    decorated: async () => {
      return await decoratedCardFields()
    },
  },
  Analytic: {
    id: 'analytic',
    name: i18n.t('importTypes.analytics'),
    icon: BsClipboardData,
    data: analyticFields,
    uploadHandler: analyticsImportHandler,
    requirements: (company) => company.user_analytics?.length > 0,
    decorated: (company) => {
      return decoratedAnalyticFields(company)
    },
  },
  Entity: {
    id: 'entity',
    name: i18n.t('importTypes.entities'),
    icon: BiBuildingHouse,
    data: entityFields,
    uploadHandler: entitiesImportHandler,
    batcher: entitiesImportBatcher,
    requirements: (company) => company.entities?.length > 0,
    decorated: (company) => {
      return decoratedEntityFields(company)
    },
  },
  Approvers: {
    id: 'approvers',
    name: i18n.t('importTypes.approvers'),
    icon: RiUserStarLine,
    data: approversFields,
    uploadHandler: approversImportHandler,
    batcher: approversImportBatcher,
    requirements: (company) => company.config?.approvalType === 'hierarchical',
  },
}

if (i18n.isInitialized) {
  updateTranslations()
}

i18n.on('languageChanged', () => {
  updateTranslations()
})

i18n.on('loaded', () => {
  updateTranslations()
})

export default ImportTypes
