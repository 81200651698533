import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export const frenchDateToAPI = (date) => {
  if (!date) {
    return ''
  }
  return dayjs(date, 'DD/MM/YYYY').format('YYYYMMDDT120000')
}

export const roleToPermission = (role) => {
  switch (role) {
    case 'pro_traveler':
      return 6179
    case 'auth_pro_traveler':
      return 7267
    case 'pro_traveler_service_searcher':
      return 268323
    case 'auth_pro_traveler_service_searcher':
      return 269411
    case 'approver':
      return 2104483
    case 'supervisor':
      return 3685875
    case 'auth_travel_manager':
      return 7539
    case 'travel_manager':
      return 7475
    case 'travel_agent':
      return 122483
    default:
      return 0
  }
}
