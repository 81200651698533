import { userFields, withRequiredAnalytics } from './userFields'

const analyticFields = [
  {
    label: "Nom d'utilisateur",
    key: 'username',
    alternateMatches: ["Nom d'utilisateur", 'utilisateur', 'login', 'LOGIN'],
    fieldType: {
      type: 'input',
    },
    example: 'jean.durand@thetreep.com',
    validations: [
      {
        rule: 'regex',
        value: '^[A-Za-z][A-Za-z0-9_.@-]{3,255}$',
        errorMessage:
          "Le format de nom d'utilisateur est non valide. Il doit comprendre au minimum 4 caractères, et ne peut contenir que les caractères spéciaux suivants: _, ., @, -",
        level: 'error',
      },
      {
        rule: 'unique',
        errorMessage: "Le nom d'utilisateur doit être unique",
        level: 'error',
      },
      {
        rule: 'required',
        errorMessage: "Le nom d'utilisateur est requis",
        level: 'error',
      },
    ],
  },
]

const decoratedAnalyticFields = (company) => {
  const af = [...analyticFields]
  if (company.user_analytics) {
    af.push(...withRequiredAnalytics(company))
  }
  return af
}

export { analyticFields, decoratedAnalyticFields }
